import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback, memo, useEffect, useMemo, } from 'react';
import classnames from 'classnames';
import { SelectButton } from './components/SelectButton';
import { SelectOption } from './components/SelectOption';
import { useDropdown } from '../../hooks';
import { EBoxDirection } from '../../types';
import styles from './SelectField.css';
function SelectFieldBase(props) {
    const { value, options, onChange, disabled = false, boxDirection = EBoxDirection.Down, label = '', placeholder = 'no option selected', getOptionValue, getOptionLabel, className, buttonClassName, dropDownContentClassName, optionClassName, optionListClassName, selectedOptionClassName, isWithTag = false, isInStickyTable = false, childNode, testId, renderDescription, descriptionClassName, } = props;
    const selectedValue = useMemo(() => {
        var _a;
        return (_a = options
            .find((item) => getOptionValue(item) === (value && getOptionValue(value)))) !== null && _a !== void 0 ? _a : null;
    }, [getOptionValue, options, value]);
    const { ref, isOpen, toggleDropdown, closeDropdown, } = useDropdown();
    const [selectedObject, setSelectedObject] = useState(selectedValue);
    useEffect(() => {
        setSelectedObject(selectedValue);
    }, [selectedValue, value]);
    useEffect(() => {
        if (disabled) {
            closeDropdown();
        }
    }, [disabled, closeDropdown]);
    const handleClick = useCallback((item) => {
        setSelectedObject(item);
        onChange(item);
        closeDropdown();
    }, [onChange, closeDropdown]);
    const [activeOption, setActiveOption] = useState(renderDescription ? selectedObject !== null && selectedObject !== void 0 ? selectedObject : options[0] : null);
    const handleMouseEnter = useCallback((opt) => {
        setActiveOption(opt);
    }, []);
    const handleMouseLeave = useCallback(() => {
        if (!renderDescription) {
            setActiveOption(null);
        }
    }, [renderDescription]);
    const handleToggleDropdown = useCallback(() => {
        if (!disabled) {
            toggleDropdown();
            setActiveOption(renderDescription ? selectedObject !== null && selectedObject !== void 0 ? selectedObject : options[0] : null);
        }
    }, [disabled, toggleDropdown, selectedObject, options, renderDescription]);
    const layoutClassName = classnames(styles.container, className, {
        [styles.disabledContainer]: disabled,
    });
    const listClassName = classnames(styles.selectList, optionListClassName, {
        [styles.open]: isOpen,
        [styles.close]: !isOpen,
    });
    const dropdownClassName = classnames(styles.dropDownContent, dropDownContentClassName, {
        [styles.dropDownContentDown]: boxDirection === EBoxDirection.Down,
        [styles.dropDownContentUp]: boxDirection === EBoxDirection.Up,
        [styles.open]: isOpen,
        [styles.close]: !isOpen,
        [styles.dropDownContentSticky]: isInStickyTable,
    });
    const currentValue = useMemo(() => (selectedObject ? getOptionLabel(selectedObject) : placeholder), [getOptionLabel, placeholder, selectedObject]);
    return (_jsxs("div", Object.assign({ className: layoutClassName, ref: ref, "data-testid": testId }, { children: [_jsx(SelectButton, { value: currentValue, label: label, onClick: handleToggleDropdown, isOpen: isOpen, buttonClassName: buttonClassName, isWithTag: isWithTag, childNode: childNode }, void 0), _jsxs("div", Object.assign({ "data-testid": "dropdown", className: dropdownClassName }, { children: [_jsx("ul", Object.assign({ className: listClassName, "aria-label": "selectList" }, { children: options.map((item) => (_jsx(SelectOption, { onClick: handleClick, label: getOptionLabel(item), value: item, isSelected: getOptionValue(item) === (selectedObject && getOptionValue(selectedObject)), isActive: getOptionValue(item) === (activeOption && getOptionValue(activeOption)), optionClassName: optionClassName, selectedOptionClassName: selectedOptionClassName, isWithTag: isWithTag, onMouseLeave: handleMouseLeave, onMouseEnter: handleMouseEnter }, getOptionValue(item)))) }), void 0), renderDescription && isOpen && (_jsx("div", Object.assign({ className: classnames(styles.description, descriptionClassName) }, { children: activeOption && renderDescription(activeOption) }), void 0))] }), void 0)] }), void 0));
}
export const SelectField = memo(SelectFieldBase);
